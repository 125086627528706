import {
  // eslint-disable-next-line camelcase
  required, email, numeric, confirmed, alpha_spaces,
} from 'vee-validate/dist/rules';
import { extend } from 'vee-validate';

extend('required', {
  ...required,
  message: 'This field is required',
});

extend('email', {
  ...email,
  message: 'This field must be a valid email',
});

extend('confirmed', {
  ...confirmed,
  message: 'This field confirmation does not match',
});

extend('required', required);
extend('numeric', numeric);
extend('alpha_spaces', alpha_spaces);
extend('len', {
  validate(value, args) {
    return `${value}`.length === parseInt(args.length, 10);
  },
  params: ['length'],
});
