<template>
  <div class="landing container">
    <b-loading :is-full-page="true" v-model="isLoading"></b-loading>
    <div v-if="!isLoading" class="columns">
      <!-- eslint-disable max-len -->
      <div
        class="column is-two-thirds-fullhd is-two-thirds-widescreen is-two-thirds-desktop is-two-thirds-tablet is-mobile"
      >
        <div
          class="landing-content"
          v-html="$root.event.pageSettings.landingContent"
        ></div>
      </div>
      <div class="column">
        <div class="card">
          <div
            class="card-content"
            style="display: flex; justify-content: center"
          >
            <b-button type="is-primary" :expanded="true" @click="empLogin()"
              >Sign in with AccessGSK</b-button
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import httpClient from '@/api/client';

export default {
  name: 'Home',
  components: {},
  data() {
    return {
      isLoading: true,
    };
  },
  methods: {
    empLogin() {
      /* eslint-disable max-len */
      /* eslint-disable operator-linebreak */
      const ssoUrl =
        'https://federation-qa.gsk.com/as/authorization.oauth2?client_id=LSSO&response_type=code&scope=country fname role openid profile roles uid lname location email legacyhrid dn DefaultAttr User.Read';
      if (this.$root.event) {
        window.location = `${ssoUrl}&state=${this.$root.event._id}`;
      } else {
        window.location = ssoUrl;
      }
    },
  },
  async created() {
    try {
      const res = await httpClient.get(
        `/event?shortLink=${this.$router.currentRoute.params.slug}`,
      );

      const { Events } = res.data.data;
      /* eslint-disable prefer-destructuring */
      this.$root.event = Events[0];
      this.isLoading = false;
    } catch (error) {
      console.log(error);
    }
  },
};
</script>
<style lang="scss">
html {
  background-color: #f5f5f5;
}
.landing {
  margin-top: 96px;
  @media screen and (max-width: 768px) {
    margin-top: 0px;
  }
}
</style>
