<template>
  <div id="app">
    <router-view />
  </div>
</template>
<script>

export default {
  data() {
    return {
      event: null,
    };
  },
};
</script>

<style lang="scss">
</style>
